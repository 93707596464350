<template>
  <div class="game-jams p-4">
    <div class="font-InterExtraBold text-xl text-white">
      Enhance your Playda! experience by naming your Game Jam and uploading a
      logo.
    </div>
    <div class="w-full flex gap-6 mt-4">
      <div class="w-full">
        <div class="bg-nColorTextInput relative rounded-3xl p-4">
          <p class="font-InterExtraBold text-xl text-white">
            Name your game jam <span class="text-red-600">*</span>
          </p>
          <p class="font-InterMedium text-base text-nColorLightGray mt-2">
            This will appear on the log in screen as the name of the event.
          </p>
          <div class="mt-3 relative">
            <input
              type="text"
              name="gameJamName"
              id="gameJamName"
              v-model="gameJamName"
              class="textInput"
              placeholder="Name your Game Jam"
              maxlength="20"
            />
            <p class="absolute right-6 top-3 text-nColorLightGray">
              Max 20 characters
            </p>
          </div>
        </div>
        <div class="bg-nColorTextInput relative rounded-3xl p-4 mt-4">
          <p class="font-InterExtraBold text-xl text-white">Upload Logo</p>
          <p class="font-InterMedium text-base text-nColorLightGray mt-2">
            Upload your Company logo in Jpeg or PNG under 2mb.
          </p>
          <div class="mt-3 relative">
            <div v-if="!logo">
              <label
                class="grid justify-items-center rounded-xl border border-dashed border-nColorBorder p-10 h-60"
                for="logo"
                v-if="!uploadingLogo"
              >
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/diy/upload_icon.webp"
                  alt="DIY Upload Icon"
                  width="47"
                  height="44"
                />
                <p class="font-InterSemiBold text-nColorLightGray">
                  Upload your logo
                </p>
                <div class="cursor-pointer">
                  <input
                    accept="image/jpg,image/jpeg,image/png"
                    ref="logo"
                    type="file"
                    name="logo"
                    id="logo"
                    class="hidden"
                    @change="logoSelected($event)"
                  />
                  <span
                    class="rounded-3xl px-4 py-2 text-nColorYellow border border-nColorYellow text-center text-xs font-InterSemiBold focus:outline-none"
                  >
                    Add new Logo
                  </span>
                </div>
              </label>
              <div v-else>
                <div
                  class="grid justify-items-center rounded-xl border border-dotted border-nColorBorderOrange p-10 h-60"
                >
                  <span class="flex justify-center items-center">
                    <svg
                      width="80"
                      height="20"
                      viewBox="0 0 120 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                    >
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="grid justify-items-center rounded-xl border border-dashed border-nColorBorder p-10"
                v-if="!uploadingLogo"
              >
                <label for="logo">
                  <img
                    :src="logo"
                    alt="Uploaded logo"
                    width="120"
                    height="120"
                    class="border border-nColorLightGray rounded-xl"
                  />
                  <div class="mt-4 cursor-pointer">
                    <input
                      accept="image/jpg,image/jpeg,image/png"
                      ref="logo"
                      type="file"
                      name="logo"
                      id="logo"
                      class="hidden"
                      @change="logoSelected($event)"
                    />
                    <div class="mt-2">
                      <span
                        class="text-nColorYellow border border-nColorYellow rounded-3xl px-4 py-1 text-center text-sm font-InterSemiBold focus:outline-none"
                      >
                        Change Logo
                      </span>
                    </div>
                  </div>
                </label>
                <div class="mt-4">
                  <a
                    @click.prevent="openModal"
                    class="text-nColorYellow font-InterSemiBold text-sm cursor-pointer"
                    >Remove logo</a
                  >
                </div>
              </div>
              <div v-else>
                <div
                  class="grid justify-items-center rounded-xl border border-dotted border-nColorBorderOrange p-10 h-60"
                >
                  <span class="flex justify-center items-center">
                    <svg
                      width="80"
                      height="20"
                      viewBox="0 0 120 30"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                    >
                      <circle cx="15" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="60" cy="15" r="9" fill-opacity="0.3">
                        <animate
                          attributeName="r"
                          from="9"
                          to="9"
                          begin="0s"
                          dur="0.8s"
                          values="9;15;9"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="0.5"
                          to="0.5"
                          begin="0s"
                          dur="0.8s"
                          values=".5;1;.5"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                      <circle cx="105" cy="15" r="15">
                        <animate
                          attributeName="r"
                          from="15"
                          to="15"
                          begin="0s"
                          dur="0.8s"
                          values="15;9;15"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                        <animate
                          attributeName="fill-opacity"
                          from="1"
                          to="1"
                          begin="0s"
                          dur="0.8s"
                          values="1;.5;1"
                          calcMode="linear"
                          repeatCount="indefinite"
                        />
                      </circle>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/diy/log_in_screen_reference.webp"
            alt="Playda Logo and Name"
            width="240"
            height="486"
          />
        </div>
        <div class="mt-4 w-full">
          <button
            @click.prevent="goNext()"
            class="bg-nColorYellow rounded-3xl w-full py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
          >
            <span> Next </span>
          </button>
          <div v-if="errorMsg" class="error text-red-600 mt-2">
            <p class="text-sm">{{ errorMsg }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-2 items-center justify-end mt-2">
      <!-- <button
        @click.prevent="updateGameJamName()"
        class="border border-nColorYellow rounded-3xl px-12 py-0.5 text-nColorYellow text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
      >
        <span> Skip </span>
      </button> -->
    </div>
    <ConfirmationModal
      @closeModal="closeModal"
      @remove="removeLogo()"
      :modal="modal"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ConfirmationModal from "@/components/Elements/ConfirmationModal.vue";

export default {
  name: "GameJamsDesktopStep1",
  components: { ConfirmationModal },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gameJamName: null,
      modal: {
        show: false,
        heading: "Remove logo",
        question: "Are you sure you want to remove your logo?",
        action: "remove",
        actionText: "remove",
        showLottie: true,
        lottiePath: "lotties/warning.json",
      },
      formData: null,
      logo: null,
      uploadingLogo: false,
      errorMsg: null,
    };
  },
  created() {},
  mounted() {
    if (
      this.gameJamData.step1 &&
      this.gameJamData.step1.name &&
      this.gameJamData.step1.name != "Untitled"
    ) {
      this.gameJamName = this.gameJamData.step1.name;
    }
    if (this.gameJamData.step1 && this.gameJamData.step1.logo_square) {
      this.logo = this.gameJamData.step1.logo_square.url;
    }
  },
  computed: {},
  methods: {
    goNext() {
      if (!this.gameJamName) {
        this.errorMsg = "Please name your Game Jam";
      } else {
        ApiService.post(apiResource.gameJams.updateGameJamName, {
          step: this.$route.params.step,
          game_jam_id: this.$route.params.game_jam_id,
          name: this.gameJamName,
        }).then((data) => {
          var dataResponse = data.data.data;
          this.$router.push({
            name: "CreateGameJam",
            params: {
              game_jam_id: dataResponse.game_jam_id,
              step: "step2",
            },
          });
        });
      }
    },
    openModal() {
      this.modal.show = true;
    },
    closeModal() {
      this.modal.show = false;
    },
    logoSelected(e) {
      this.uploadingLogo = true;
      this.themeSelection = false;
      this.darkImage = null;
      this.lightImage = null;
      this.formData = new FormData();
      this.formData.append("logo", e.target.files[0]);
      this.formData.append("game_jam_id", this.$route.params.game_jam_id);
      this.formData.append("step", this.$route.params.step);
      ApiService.simplePost(
        apiResource.gameJams.uploadLogo,
        this.formData
      ).then((data) => {
        this.logo = data.data.data.logo_square.url;
        this.uploadingLogo = false;
      });
    },
    removeLogo() {
      ApiService.post(apiResource.gameJams.removeLogo, {
        game_jam_id: this.$route.params.game_jam_id,
        step: this.$route.params.step,
      }).then(() => {
        this.logo = null;
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.textInput {
  @apply font-InterBold w-full text-nColorOrange px-4 py-3 focus:outline-none focus:border-gray-500 bg-nColorButtonText rounded-xl;
}
</style>
