<template>
  <div class="select-games pt-2" v-if="data">
    <div class="font-InterExtraBold text-base text-white px-4">
      Choose up to 4 games. Click on the game tiles to preview and know more.
    </div>
    <div class="flex gap-2 mt-3 overflow-x-auto px-4">
      <div
        v-for="cat in categories"
        :key="cat"
        :class="{
          'bg-nColorLightGray text-nColorButtonText': selectedCat === cat,
          'bg-transparent text-nColorLightGray': selectedCat != cat,
        }"
        class="border border-nColorLightGray rounded-2xl font-InterSemiBold text-sm px-4 py-0.5 capitalize cursor-pointer"
        @click="selectedCat = cat"
      >
        {{ cat }}
      </div>
    </div>
    <div class="mt-4 grid gap-2 pb-20 px-4">
      <div class="grid grid-cols-2 gap-2">
        <div
          v-for="(game, index) in getGames"
          :key="game.slug"
          class="bg-nColorTextInput rounded-2xl grid justify-items-center content-center gap-4 relative mb-2 cursor-pointer"
          @click.prevent="openGameData(index)"
        >
          <div>
            <img :src="getGameTile(game.slug)" :alt="game.name" />
          </div>
          <div
            class="absolute -bottom-1 left-0 bg-nColorAddedGreen bg-opacity-90 w-full rounded-b-2xl py-1 z-10"
            @click.once.prevent.stop="removeGame(game.slug)"
            v-if="isSlugPresent(game.slug)"
          >
            <div class="flex justify-center items-center gap-1">
              <div>
                <img
                  src="https://playda.s3.ap-south-1.amazonaws.com/diy/added_game.webp"
                  width="15"
                  height="15"
                />
              </div>
              <div class="text-white font-InterSemiBold text-sm">Remove</div>
            </div>
          </div>
          <div
            class="absolute -bottom-1 left-0 bg-black w-full rounded-b-2xl py-1 z-10"
            @click.once.prevent.stop="addGame(game)"
            v-else
          >
            <div class="flex justify-center items-center">
              <div class="text-nColorYellow font-InterSemiBold text-sm">
                + Add game
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isBackdropVisible"
        class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
      ></div>
    </transition>
    <transition name="slide-up">
      <div
        v-if="isGameJamDetailsVisible"
        class="fixed bottom-0 left-0 w-full z-50"
      >
        <div class="w-full relative">
          <div
            class="absolute -top-10 transform -translate-x-1/2 left-1/2"
            @click.prevent="toggleGameJamDetails"
          >
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
              alt="Close Game Jam Details"
              width="25"
              height="25"
            />
          </div>
          <div
            :class="{
              'grid justify-items-center content center':
                selectedGames.length === 0,
            }"
            class="bg-nColorTextInput rounded-t-2xl"
          >
            <div
              class="grid justify-items-center content-center gap-4 h-96"
              v-if="selectedGames.length === 0"
            >
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/diy/game_jam_will_appear_here.webp"
                alt="DIY Game Jam Games"
                width="100"
                height="79"
              />
              <p
                class="px-4 text-nColorLightGray font-InterSemiBold text-sm text-center"
              >
                Your Game Jam will appear here. Select a game to begin.
              </p>
            </div>
            <div v-else class="p-4">
              <div class="flex justify-between items-center">
                <p class="font-InterBold text-white text-base">Your Game Jam</p>
                <button
                  class="font-ZuumeMedium px-3 rounded-3xl text-base border border-nColorYellow text-nColorYellow bg-nColorTextInput"
                  @click.prevent="clearGames()"
                >
                  Clear
                </button>
              </div>
              <p class="font-InterMedium text-nColorLightGray text-sm py-2">
                Use the drag icon to arrange games in the order you'd like
              </p>
              <div>
                <draggable :list="selectedGames" class="grid gap-2">
                  <div v-for="item in selectedGames" :key="item.slug">
                    <div
                      class="bg-nColorButtonText border border-nColorBorder rounded-2xl flex items-start gap-1 relative p-2"
                    >
                      <div>
                        <div
                          class="bg-nColorTextInput rounded-xl w-12 h-16 flex justify-center items-center"
                        >
                          <img
                            :src="getGameTile(item.slug)"
                            :alt="item.name"
                            class="w-12"
                          />
                        </div>
                      </div>
                      <div class="w-full flex justify-between gap-2">
                        <div>
                          <div class="text-white font-InterBold text-sm">
                            {{ item.name }}
                          </div>
                          <div
                            class="text-nColorLightGray font-InterBold text-xs"
                          >
                            Game duration: {{ item.duration }} mins
                          </div>
                        </div>
                        <div class="grid content-center justify-items-center">
                          <div
                            class="cursor-pointer"
                            @click="removeGame(item.slug)"
                          >
                            <img
                              src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
                              alt="DIY Game Jam Remove Game"
                              width="20"
                              height="20"
                            />
                          </div>
                          <div class="mt-4">
                            <img
                              src="https://playda.s3.ap-south-1.amazonaws.com/diy/rearrange.webp"
                              alt="DIY Game Jam Rearrange Game"
                              width="12"
                              height="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="isGameDataBackdropVisible"
        class="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
      ></div>
    </transition>
    <transition name="slide-up">
      <div v-if="isGameDataVisible" class="fixed bottom-0 left-0 w-full z-50">
        <div class="w-full relative">
          <div
            class="absolute -top-10 transform -translate-x-1/2 left-1/2"
            @click.prevent="toggleGameDataDetails"
          >
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
              alt="Close Game Data"
              width="25"
              height="25"
            />
          </div>
          <div class="p-4 bg-black rounded-t-2xl">
            <div class="grid gap-2">
              <div class="grid content-between w-full">
                <div class="grid content-start gap-4">
                  <div class="flex justify-between items-center">
                    <div class="font-InterBold text-lg text-white">
                      {{ currentGame.name }}
                    </div>
                    <div>
                      <div
                        class="text-center border border-nColorLightGray rounded-2xl font-InterSemiBold text-sm px-4 capitalize text-nColorLightGray"
                      >
                        {{ currentGame.cat }}
                      </div>
                    </div>
                  </div>
                  <div class="font-InterMedium text-nColorLightGray text-sm">
                    {{ currentGame.desc }}
                  </div>
                </div>
              </div>
              <div class="flex justify-center">
                <div v-if="currentGame.slug">
                  <video
                    :src="getGamePreview(currentGame.slug)"
                    controls
                    class="w-48 rounded-lg"
                    autoplay
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      class="fixed bottom-0 w-full h-16 bg-nColorTextInput z-20 border-t border-nColorBorder rounded-t-2xl"
    >
      <div class="flex gap-2 items-center px-2 pb-2 pt-4">
        <div class="w-full relative">
          <div
            :class="{
              'bg-nColorTextInput text-nColorYellow':
                selectedGames.length === 0,
              'bg-nColorAddedGreen text-white':
                selectedGames.length > 0 && selectedGames.length < 4,
              'bg-nColorRed text-white': selectedGames.length === 4,
            }"
            class="rounded-full w-5 h-5 text-center text-xs font-bold border border-nColorYellow absolute -top-2 right-1.5"
          >
            {{ selectedGames.length }}
          </div>
          <button
            @click.prevent="toggleGameJamDetails"
            class="w-full bg-nColorButtonText border border-nColorYellow rounded-3xl py-0.5 text-nColorYellow text-center uppercase text-2xl font-ZuumeSemiBold focus:outline-none"
          >
            <span> View Game Jam </span>
          </button>
        </div>
        <button
          @click.prevent="goNext()"
          class="w-full bg-nColorYellow border border-nColorYellow rounded-3xl py-0.5 text-nColorButtonText text-center uppercase text-2xl font-ZuumeSemiBold focus:outline-none"
        >
          <span> Proceed </span>
        </button>
      </div>
    </div>
    <ExceededLimitModal
      @closeModal="closeExceededLimitModal"
      :modal="exceededLimitModal"
    />
    <GameDataModal
      :modal="gameDataModal"
      :game="currentGame"
      :games="getGames"
      :previewBasePath="previewBasePath"
      :current-index="currentIndex"
      @closeModal="closeGameDataModal"
      @prev="goToPrevGame"
      @next="goToNextGame"
      @addGame="addGame"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { defineComponent } from "vue";
import { VueDraggableNext } from "vue-draggable-next";
import ExceededLimitModal from "@/components/Elements/ExceededLimitModal.vue";
import GameDataModal from "@/components/Elements/GameDataModal.vue";

export default defineComponent({
  name: "GameJamsDesktopStep2",
  components: {
    draggable: VueDraggableNext,
    ExceededLimitModal,
    GameDataModal,
  },
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      categories: ["all", "action", "puzzle", "trivia", "word", "sport"],
      selectedCat: "all",
      selectedGames: [],
      currentIndex: 0,
      exceededLimitModal: {
        show: false,
        heading: "4 games added",
        question:
          "You have added the maximum number of games in this Game Jam.",
        showLottie: true,
        lottiePath: "lotties/exceeded-limit.json",
      },
      gameDataModal: false,
      previewBasePath: null,
      tileBasePath: null,
      isBackdropVisible: false,
      isGameJamDetailsVisible: false,
      isGameDataBackdropVisible: false,
      isGameDataVisible: false,
    };
  },
  created() {
    ApiService.get(apiResource.gameJams.getGames, {}).then((data) => {
      this.data = data.data.data.games;
      this.previewBasePath = data.data.data.preview_base_path;
      this.tileBasePath = data.data.data.tiles_base_path;
    });
    if (this.gameJamData.step2 && this.gameJamData.step2 != null) {
      this.selectedGames = this.gameJamData.step2;
    }
  },
  computed: {
    getGames() {
      if (!this.data) return [];
      if (this.selectedCat === "all") {
        return Object.values(this.data).flat();
      }
      return this.data[this.selectedCat] || [];
    },
    currentGame() {
      return this.getGames[this.currentIndex] || null;
    },
  },
  methods: {
    toggleGameJamDetails() {
      if (this.isBackdropVisible) {
        this.isGameJamDetailsVisible = false; // Hide content first
        setTimeout(() => {
          this.isBackdropVisible = false; // Then remove the backdrop
        }, 400); // Match this to the transition duration
      } else {
        // If not visible, show backdrop and then slide content
        this.isBackdropVisible = true;
        setTimeout(() => {
          this.isGameJamDetailsVisible = true; // Slide content after a delay
        }, 50); // Slight delay to ensure the backdrop shows first
      }
    },
    toggleGameDataDetails() {
      if (this.isGameDataBackdropVisible) {
        this.isGameDataVisible = false; // Hide content first
        setTimeout(() => {
          this.isGameDataBackdropVisible = false; // Then remove the backdrop
        }, 400); // Match this to the transition duration
      } else {
        // If not visible, show backdrop and then slide content
        this.isGameDataBackdropVisible = true;
        setTimeout(() => {
          this.isGameDataVisible = true; // Slide content after a delay
        }, 50); // Slight delay to ensure the backdrop shows first
      }
    },
    getGameTile(slug) {
      return this.tileBasePath + slug + ".webp";
    },
    getGamePreview(slug) {
      return this.previewBasePath + slug + ".mp4";
    },
    openGameData(index) {
      this.currentIndex = index;
      this.toggleGameDataDetails();
    },
    closeGameDataModal() {
      this.gameDataModal = false;
    },
    goToPrevGame() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    goToNextGame() {
      if (this.currentIndex < this.getGames.length - 1) {
        this.currentIndex++;
      }
    },
    openExceededLimitModal() {
      this.exceededLimitModal.show = true;
    },
    closeExceededLimitModal() {
      this.exceededLimitModal.show = false;
    },

    addGame(game) {
      // Check if there are already 4 games in the array
      if (this.selectedGames.length >= 4) {
        this.openExceededLimitModal();
        return;
      }

      // Add the new game
      this.selectedGames.push({ ...game, duration: 0 });

      // Update the duration of each game based on the number of selected games
      this.updateGameDurations();
    },
    updateGameDurations() {
      const numGames = this.selectedGames.length;
      let duration;

      // Set the duration based on the number of games
      switch (numGames) {
        case 1:
          duration = 60;
          break;
        case 2:
          duration = 30;
          break;
        case 3:
          duration = 20;
          break;
        case 4:
          duration = 15;
          break;
      }

      // Assign the duration to each game in the array
      this.selectedGames.forEach((game) => {
        game.duration = duration;
      });
    },
    removeGame(slug) {
      this.selectedGames = this.selectedGames.filter(
        (game) => game.slug !== slug
      );
      this.updateGameDurations();
    },
    clearGames() {
      this.selectedGames = [];
    },
    isSlugPresent(slug) {
      return this.selectedGames.some((game) => game.slug === slug);
    },
    goNext() {
      ApiService.post(apiResource.gameJams.saveProgress, {
        step: this.$route.params.step,
        game_jam_id: this.$route.params.game_jam_id,
        data: this.selectedGames,
      }).then((data) => {
        var dataResponse = data.data.data;
        this.$router.push({
          name: "CreateGameJam",
          params: {
            game_jam_id: dataResponse.game_jam_id,
            step: "step3",
          },
        });
      });
    },
  },
});
</script>

<style scoped lang="postcss">
.select-games {
  height: 100dvh;
}
/* Slide up animation */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}
.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

/* Fade for the backdrop */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

/* Blurred backdrop */
.backdrop-blur-sm {
  backdrop-filter: blur(10px); /* You can adjust the blur amount */
}
</style>
