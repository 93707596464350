<template>
  <div class="px-4 relative mt-3">
    <div class="wizard-container">
      <div ref="wizardSteps" class="flex overflow-x-auto gap-3">
        <div v-for="(step, index) in wizard.steps" :key="index" class="py-2">
          <div
            :class="{
              'border-b-2 border-nColorOrange': isCurrentStep(step.slug),
            }"
            class="flex items-center"
          >
            <div>
              <span
                class="w-5 h-5 text-sm font-InterBold flex justify-center items-center text-nColorYellow"
                v-if="isCurrentStep(step.slug)"
                >{{ index + 1 }}.</span
              ><span
                class="w-5 h-5 text-sm font-InterBold flex justify-center items-center text-nColorGreen"
                v-else-if="isStepComplete(step.slug)"
                >&#10003;</span
              ><span
                v-else
                class="w-5 h-5 text-sm font-InterBold flex justify-center items-center text-white"
                >{{ index + 1 }}.</span
              >
            </div>
            <div class="whitespace-nowrap">
              <p
                :class="{
                  'text-nColorYellow': isCurrentStep(step.slug),
                  'text-white': !isCurrentStep(step.slug),
                }"
                class="font-InterSemiBold text-sm"
              >
                {{ step.text }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wizard from "@/common/wizard.config";

export default {
  name: "MobileWizard",
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      username: this.$store.getters.profile.name,
      email: this.$store.getters.profile.email,
      wizard,
    };
  },
  mounted() {
    this.scrollToCurrentStep();
  },
  methods: {
    goToDashboard() {
      this.$router.push({
        name: "Dashboard",
      });
    },
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
    isStepComplete(slug) {
      if (
        Object.hasOwnProperty.call(this.gameJamData, slug) &&
        this.gameJamData[slug] !== null
      ) {
        return true;
      }

      return false;
    },
    isCurrentStep(slug) {
      if (this.$route.params.step === slug) {
        return true;
      }
      return false;
    },
    scrollToCurrentStep() {
      const currentStepIndex = this.wizard.steps.findIndex(
        (step) => step.slug === this.$route.params.step
      );

      // Check if the current step is the first step
      if (currentStepIndex === 0) {
        return; // Skip scrolling for step 1
      }

      if (currentStepIndex !== -1) {
        const stepElement = this.$refs.wizardSteps.children[currentStepIndex];
        if (stepElement) {
          // Scroll the wizard container instead of the page
          this.$refs.wizardSteps.scrollTo({
            left: stepElement.offsetLeft,
            behavior: "smooth",
          });

          // Optional: If stepElement is not fully visible, adjust scroll position
          const wizardContainer = this.$refs.wizardSteps.parentElement; // Get the wizard container
          const wizardContainerWidth = wizardContainer.clientWidth; // Get the width of the wizard container
          const stepElementWidth = stepElement.offsetWidth; // Get the width of the current step element

          // Ensure current step is within the visible area
          if (
            stepElement.offsetLeft + stepElementWidth >
            wizardContainerWidth
          ) {
            this.$refs.wizardSteps.scrollLeft = stepElement.offsetLeft; // Adjust scroll position to show current step
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.wizard-container {
  max-height: 100px; /* Set a height suitable for your design */
  overflow-y: hidden; /* Prevent vertical overflow */
  overflow-x: auto; /* Allow horizontal scrolling */
}
</style>
