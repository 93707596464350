<template>
  <div v-if="modal" class="modal">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-nColorButtonText bg-opacity-30 modal-backdrop"
    >
      <div
        class="modal-width bg-black border border-nColorBorder rounded-3xl text-white"
      >
        <div class="p-4">
          <div class="flex gap-2">
            <div>
              <div v-if="game.slug">
                <video
                  :src="getGamePreview(game.slug)"
                  controls
                  class="w-80 rounded-lg"
                  autoplay
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <div class="grid content-between w-full pl-4">
              <div class="grid content-start gap-4">
                <div class="w-full flex items-start justify-end">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/diy/remove.webp"
                    alt="DIY Game Jam Remove Game"
                    width="30"
                    height="30"
                    class="cursor-pointer"
                    @click="closeModal"
                  />
                </div>
                <div class="font-InterBold text-3xl">
                  {{ game.name }}
                </div>
                <div>
                  <div
                    class="w-24 text-center border border-nColorLightGray rounded-2xl font-InterSemiBold text-base px-3 py-0.5 capitalize text-nColorLightGray"
                  >
                    {{ game.cat }}
                  </div>
                </div>
                <div class="font-InterMedium text-nColorLightGray">
                  {{ game.desc }}
                </div>
              </div>
              <div class="grid content-start gap-4 w-full pl-4 pb-20">
                <!-- <div class="flex gap-4">
                  <button
                    @click="addGameToList(game)"
                    class="bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
                  >
                    <span> Add game </span>
                  </button>
                  <button
                    :disabled="currentIndex === games.length - 1"
                    @click="$emit('next')"
                    class="border border-nColorYellow rounded-3xl px-12 py-0.5 text-nColorYellow text-center uppercase text-3xl font-ZuumeSemiBold tracking-wider focus:outline-none"
                  >
                    <span> Next Game </span>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameDataModal",
  components: {},
  props: {
    modal: {
      type: Boolean,
      required: true,
    },
    game: {
      type: Object,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
    previewBasePath: {
      type: String,
      required: true,
    },
  },

  methods: {
    getGamePreview(slug) {
      return this.previewBasePath + slug + ".mp4";
    },
    closeModal() {
      this.$emit("closeModal", false);
    },
    addGameToList(game) {
      this.$emit("addGame", game);
    },
  },
};
</script>

<style scoped>
.modal-width {
  width: 820px;
}
.mobile-height {
  height: 75%;
}
.modal-backdrop {
  backdrop-filter: blur(2px);
}
</style>
