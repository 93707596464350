<template>
  <div class="summary px-4">
    <div class="text-lg text-white">
      <p class="font-InterExtraBold">Yahoo! Your Game Jam is almost set up.</p>
      <div class="text-nColorLightGray text-sm my-3 font-InterMedium">
        <p>Next Steps:</p>
        <ul>
          <li class="my-1">1. Share the QR and Link with your audience.</li>
          <li class="my-1">2. Click on Finalize to complete the set up.</li>
          <li class="my-1">
            3. Hit 'Start' on the next screen to take the game jam LIVE.
          </li>
        </ul>
      </div>
    </div>
    <div class="grid gap-4 mt-4">
      <div class="bg-nColorTextInput rounded-2xl p-4">
        <p class="text-white font-InterSemiBold">1. Download the QR code.</p>
        <div v-if="qrCode" class="mt-4">
          <div class="grid justify-center gap-2">
            <div class="pb-4">
              <img :src="qrCode" width="320" height="320" class="rounded-2xl" />
            </div>
          </div>
          <div>
            <button
              class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-xl w-full"
              @click.prevent="downloadQR"
            >
              Download QR Code
            </button>
          </div>
        </div>
      </div>
      <div class="bg-nColorTextInput rounded-2xl p-4">
        <p class="text-white font-InterSemiBold">2. Copy & Share the link.</p>
        <div v-if="link" class="mt-4">
          <div class="grid gap-2">
            <div class="pb-4 w-64">
              <p
                class="text-nColorYellow font-InterExtraBold italic break-all pt-6"
              >
                {{ link }}
              </p>
            </div>
          </div>
          <div>
            <button
              class="font-ZuumeSemiBold border border-nColorYellow text-nColorYellow py-0.5 rounded-3xl text-xl w-full"
              @click.once.prevent="copyLink"
            >
              Copy Link
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full mt-4 mb-8">
      <button
        @click.prevent="createGameJam()"
        class="w-full bg-nColorYellow rounded-3xl px-12 py-0.5 text-nColorButtonText text-center uppercase text-3xl font-ZuumeSemiBold focus:outline-none"
      >
        <span> Finalize </span>
      </button>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "GameJamsDesktopStep4",
  components: {},
  props: {
    gameJamData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      qrCode: null,
      presignedURL: null,
      link: null,
    };
  },
  created() {
    if (!this.gameJamData.step4) {
      this.$router.replace({
        name: "CreateGameJam",
        params: {
          game_jam_id: this.$route.params.game_jam_id,
          step: "step3",
        },
      });
    }
    this.qrCode = this.gameJamData.step4.qrcode;
    this.presignedURL = this.gameJamData.step4.presigned_url;
    this.link = this.gameJamData.step4.link;
  },
  computed: {},
  methods: {
    createGameJam() {
      ApiService.post(apiResource.gameJams.createGameJam, {
        step: this.$route.params.step,
        game_jam_id: this.$route.params.game_jam_id,
      }).then(() => {
        this.$router.push({
          name: "Dashboard",
        });
      });
    },
    copyLink() {
      const textarea = document.createElement("textarea");
      textarea.value = this.link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.success("Link copied to clipboard!", { timeout: 2000 });
    },
    downloadQR() {
      ApiService.post(apiResource.downloadQRCode, {
        game_jam_id: this.$route.params.game_jam_id,
      })
        .then((data) => {
          const imageUrl = data.data.data.url; // Presigned URL
          fetch(imageUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.blob(); // Convert response to Blob
            })
            .then((blob) => {
              const link = document.createElement("a");
              link.href = URL.createObjectURL(blob); // Create a URL for the Blob
              link.download =
                "Playda Game Jam - " + data.data.data.name + ".png"; // Set the file name
              document.body.appendChild(link); // Append to the body
              link.click(); // Trigger download
              document.body.removeChild(link); // Clean up
              URL.revokeObjectURL(link.href); // Clean up the object URL
            })
            .catch((error) => {
              console.error("Download failed:", error); // Log any errors
            });
        })
        .catch((error) => {
          console.error("API call failed:", error);
        });
    },
  },
};
</script>

<style scoped lang="postcss">
.summary {
  height: 100dvh;
}
</style>
